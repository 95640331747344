// @flow
import * as React from 'react';
import { Transition, animated, config } from 'react-spring';

type PhysicsType =
  | 'default'
  | 'molasses'
  | 'slow'
  | 'stiff'
  | 'wobbly'
  | 'gentle'
  | {};

const ANIMATION_CONFIG = (fromValue: number, toValue: number) => ({
  FADE_VERTICAL: {
    interpolation: props =>
      props.y.interpolate(y => `translate3d(0, ${y}px, 0)`),
    from: {
      opacity: 0,
      y: fromValue,
    },
    to: { opacity: 1, y: toValue },
  },
  FADE_HORIZONTAL: {
    interpolation: props =>
      props.x.interpolate(x => `translate3d(${x}px, 0, 0)`),
    from: {
      opacity: 0,
      x: fromValue,
    },
    to: { opacity: 1, x: toValue },
  },
  FADE: {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
});

const BaseTransition = ({
  children,
  delay,
  type,
  fromValue,
  toValue,
  physics,
  styles,
}: {
  children: React.Node,
  delay: number,
  type: 'FADE' | 'FADE_VERTICAL' | 'FADE_HORIZONTAL',
  fromValue: number,
  toValue: number,
  physics: PhysicsType,
  styles: {},
}) => {
  const animationProps = ANIMATION_CONFIG(fromValue, toValue)[type];
  const configProp = typeof physics === 'string' ? config[physics] : physics;
  return (
    <Transition
      items={React.Children.map(children, child => child)}
      keys={React.Children.map(children, (_, i) => i)}
      from={animationProps.from}
      enter={animationProps.to}
      config={configProp}
      delay={delay}
      native
    >
      {item => (...props) => (
        <animated.div
          style={{
            opacity: props[0].opacity,
            transform: animationProps.interpolation
              ? animationProps.interpolation(props[0])
              : '',
            ...styles,
          }}
        >
          {item}
        </animated.div>
      )}
    </Transition>
  );
};

BaseTransition.defaultProps = {
  physics: 'molasses',
  styles: {},
};

export const Fade = ({ from, to, ...props }) => (
  <BaseTransition type="FADE" fromValue={from} toValue={to} {...props} />
);

Fade.defaultProps = {
  delay: 0,
  from: 0,
  to: 1,
  physics: 'molasses',
};

type VerticalFade = {
  children: React.Node,
  delay: number,
  yFrom: number,
  yTo: number,
  physics: PhysicsType,
};

export const FadeUp = ({ yFrom, yTo, ...props }: VerticalFade) => (
  <BaseTransition
    type="FADE_VERTICAL"
    fromValue={yFrom}
    toValue={yTo}
    {...props}
  />
);

FadeUp.defaultProps = {
  delay: 0,
  yFrom: 50,
  yTo: 0,
  physics: 'molasses',
};

export const FadeDown = ({ yFrom, yTo, ...props }: VerticalFade) => (
  <BaseTransition
    type="FADE_VERTICAL"
    fromValue={yFrom}
    toValue={yTo}
    {...props}
  />
);

FadeDown.defaultProps = {
  delay: 0,
  yFrom: -50,
  yTo: 0,
  physics: 'molasses',
};

type HorizontalFade = {
  children: React.Node,
  delay: number,
  xFrom: number,
  xTo: number,
  physics: PhysicsType,
};

export const FadeLeft = ({ xFrom, xTo, ...props }: HorizontalFade) => (
  <BaseTransition
    type="FADE_HORIZONTAL"
    fromValue={xFrom}
    toValue={xTo}
    {...props}
  />
);

FadeLeft.defaultProps = {
  delay: 0,
  xFrom: 50,
  xTo: 0,
  physics: 'molasses',
};

export const FadeRight = ({ xFrom, xTo, ...props }: HorizontalFade) => (
  <BaseTransition
    type="FADE_HORIZONTAL"
    fromValue={xFrom}
    toValue={xTo}
    {...props}
  />
);

FadeRight.defaultProps = {
  delay: 0,
  xFrom: -50,
  xTo: 0,
  physics: 'molasses',
};
