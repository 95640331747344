import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const CanvasWrapper = styled.div`
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.9;
`;

export default class CSCanvas extends React.Component {
  static propTypes = {
    canvasWidth: PropTypes.number,
    canvasHeight: PropTypes.number,
    colorStops: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
    startX: PropTypes.number,
    startY: PropTypes.number,
    linearGradient: PropTypes.shape({
      x0: PropTypes.number,
      y0: PropTypes.number,
      x1: PropTypes.number,
      y1: PropTypes.number,
    }),
  };

  static defaultProps = {
    canvasWidth: 0,
    canvasHeight: 0,
    colorStops: {
      shape: { from: '#ffffff', to: '#ffffff' },
      shapeExtension: { from: '#ffffff', to: '#ffffff' },
    },
    startX: 0,
    startY: 0,
    linearGradient: {
      x0: null,
      y0: null,
      x1: null,
      y1: null,
    },
    shape: [],
    shapeExtension: [],
  };

  canvas = React.createRef();

  componentDidMount() {
    this.drawShapeOnCanvas();
  }

  componentDidUpdate(prevProps) {
    const { shape, canvasHeight } = this.props;
    if (
      (shape.length > 0 && prevProps.shape.length === 0) ||
      canvasHeight !== prevProps.canvasHeight
    ) {
      this.drawShapeOnCanvas();
    }
  }

  drawShapeOnCanvas = () => {
    if (!this.canvas.current || this.props.shape.length < 1) {
      return;
    }

    const {
      canvasWidth,
      canvasHeight,
      colorStops,
      shape,
      shapeExtension,
      startX,
      startY,
      linearGradient,
    } = this.props;

    const context = this.canvas.current.getContext('2d');
    const dpi = window.devicePixelRatio || 1;

    // test
    // set pixel ratio to prevent blurring
    context.pixelRatio = dpi;

    // setup gradient fill
    const gradientMap = {
      x0: linearGradient.x0 == null ? 0 : linearGradient.x0,
      y0: linearGradient.y0 == null ? canvasHeight / 2 : linearGradient.y0,
      x1: linearGradient.x1 == null ? canvasWidth : linearGradient.x1,
      y1: linearGradient.y1 == null ? canvasHeight / 2 : linearGradient.y1,
    };
    const gradient = context.createLinearGradient(
      gradientMap.x0,
      gradientMap.y0,
      gradientMap.x1,
      gradientMap.y1
    );
    gradient.addColorStop(0, colorStops.shape.from);
    gradient.addColorStop(0.75, colorStops.shape.to);

    // draw shape
    context.fillStyle = gradient;

    context.beginPath();
    context.moveTo(startX, startY);
    shape.forEach(scene => {
      scene.forEach(point => {
        if (point.length === 2) {
          // 2 x points = straight line]
          context.lineTo(point[0], point[1]);
        } else if (point.length === 4) {
          // 4 x points = quadratic curve
          context.quadraticCurveTo(point[0], point[1], point[2], point[3]);
        } else if (point.length === 6) {
          // 6 x points = bezier curve
          context.bezierCurveTo(
            point[0],
            point[1],
            point[2],
            point[3],
            point[4],
            point[5]
          );
        }
      });
    });

    context.closePath();
    context.fill();

    context.beginPath();
    context.moveTo(startX, startY);
    // setup  extension gradient fill

    const extensionGradient = context.createLinearGradient(
      gradientMap.x0,
      gradientMap.y0,
      gradientMap.x1,
      gradientMap.y1
    );

    extensionGradient.addColorStop(0, colorStops.shapeExtension.from);
    extensionGradient.addColorStop(0.75, colorStops.shapeExtension.to);

    // draw shape
    context.fillStyle = extensionGradient;

    shapeExtension.forEach(scene => {
      scene.forEach(point => {
        if (point.length === 2) {
          // 2 x points = straight line]
          context.lineTo(point[0], point[1]);
        } else if (point.length === 4) {
          // 4 x points = quadratic curve
          context.quadraticCurveTo(point[0], point[1], point[2], point[3]);
        } else if (point.length === 6) {
          // 6 x points = bezier curve
          context.bezierCurveTo(
            point[0],
            point[1],
            point[2],
            point[3],
            point[4],
            point[5]
          );
        }
      });
    });

    context.closePath();
    context.fill();
  };

  // /**
  //  * converts hex color + opacity to rgba
  //  * @param {hex} string e.g. #525D73
  //  * @param {opacity} number e.g. 0.8
  //  * @returns {string} e.g 'rgba(82,93,115, 0.8)'
  //  */
  // hex2rgba = (hex, opacity) => {
  //   const r = parseInt(hex.slice(1, 3), 16)
  //   const g = parseInt(hex.slice(3, 5), 16)
  //   const b = parseInt(hex.slice(5, 7), 16)
  //   return `rgba(${r}, ${g}, ${b}, ${opacity})`
  // }

  render() {
    const { canvasWidth, canvasHeight } = this.props;
    // keep empty string in canvas to prevent from self closing due to browser bugs
    return (
      <CanvasWrapper>
        <canvas
          ref={this.canvas}
          width={canvasWidth}
          height={canvasHeight}
          style={{ verticalAlign: 'bottom' }}
        >
          {''}
        </canvas>
      </CanvasWrapper>
    );
  }
}
