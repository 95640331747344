import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { Banner, NoticeText, BannerButton } from './index.styles';
import { getCookie, setCookie } from '../../utils/general';

const COOKIE_NOTICE = {
  key: 'mcon_privacy_notice',
  value: 'accepted',
};

export default function() {
  const [showNotice, setShowNotice] = useState(false);
  const handleLink = () => {
    setCookie(COOKIE_NOTICE.key, COOKIE_NOTICE.value);
    setShowNotice(false);
  };

  useEffect(() => {
    setShowNotice(!getCookie(COOKIE_NOTICE.key));
  }, []);

  return (
    showNotice && (
      <Banner>
        <NoticeText>
          Vi använder cookies för att säkerställa och förbättra funktionaliteten
          på vår hemsida
        </NoticeText>
        <Link to="/integritetspolicy">
          <BannerButton onClick={handleLink}>Läs mer</BannerButton>
        </Link>
        <BannerButton onClick={handleLink}>Stäng</BannerButton>
      </Banner>
    )
  );
}
