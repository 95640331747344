// @flow
import * as React from 'react';
import { Link } from 'gatsby';
import { FadeUp } from '../Animations';
import Button from '../Button';
import { sizes } from '../../utils/style-utils';
import { Logo, ContactWrapper } from './styles';
import MobileMenu from '../Menu/MobileMenu';
import DesktopMenu from '../Menu/DesktopMenu';

export type NavigationItem = {
  url: string,
  pathname: string,
  title: string,
};

export type MenuNavigationConfig = {
  services: NavigationItem,
  about: NavigationItem,
  references: NavigationItem,
};

export type NavigationConfig = MenuNavigationConfig & {
  contact: NavigationItem,
  position: NavigationItem,
  privacy: NavigationItem,
};

export const navConfig: NavigationConfig = {
  services: {
    url: '/tjanster',
    pathname: '/Services',
    title: 'Tjänster',
  },
  about: {
    url: '/om-oss',
    pathname: '/About',
    title: 'Om oss',
  },
  references: {
    url: '/referenser',
    pathname: '/References',
    title: 'Referenser',
  },
  contact: {
    url: '/kontakt',
    pathname: '/Contact',
    title: 'Contact',
  },
  privacy: {
    url: '/integritetspolicy',
    pathname: '/Privacy',
    title: 'Privacy',
  },
  position: {
    url: '/lediga-tjanster',
    pathname: '/Position',
    title: 'Position',
  },
};

type State = {
  isMobile: boolean,
  vh: number,
  vw: number,
  menuOpen: boolean,
};

type Props = { currentPage: string };

export class Navigation extends React.Component<Props, State> {
  state = {
    isMobile: false,
    vh: 600,
    vw: 0,
    menuOpen: false,
  };
  mobileMenu = null;
  desktopMenu = null;
  resizeTimeout = null;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    const isMobile = window.innerWidth < sizes.tablet;

    this.setState({
      isMobile,
      vh: window.innerHeight,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (this.resizeTimeout !== null) {
      clearTimeout(this.resizeTimeout);
    }

    this.resizeTimeout = setTimeout(() => {
      const isMobile = window.innerWidth < sizes.tablet;
      if (isMobile === this.state.isMobile) {
        return;
      }

      this.setState({
        isMobile,
      });
    }, 500);
  };

  toggleMobileMenu = e => {
    this.setState(
      ({ menuOpen }) => ({
        menuOpen: !menuOpen,
      }),
      () => {
        if (this.state.menuOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'scroll';
        }
      }
    );
  };

  closeMobileMenu = e => {
    this.setState(
      {
        menuOpen: false,
      },
      () => {
        document.body.style.overflow = 'scroll';
      }
    );
  };

  render() {
    const { isMobile, menuOpen } = this.state;
    const { currentPage } = this.props;
    const {
      services,
      about,
      references,
      contact,
      privacy,
      position,
    } = navConfig;

    const invertNavColors = currentPage !== privacy.url;
    const showContactButton =
      currentPage !== contact.url && currentPage !== position.url;
    const showMobileMenu = isMobile;
    const showDesktopMenu = !isMobile;

    return (
      <div>
        <Link to={services.url} id="test-id-home-link">
          <Logo inverted={invertNavColors && currentPage !== position.url} />
        </Link>

        {showMobileMenu ? (
          <MobileMenu
            menuConfig={{ services, about, references }}
            onMenuToggle={this.toggleMobileMenu}
            currentPage={currentPage}
            open={menuOpen}
          />
        ) : showDesktopMenu ? (
          <DesktopMenu
            inverted={invertNavColors}
            menuConfig={{ services, about, references }}
            currentPage={currentPage}
          />
        ) : null}

        {showContactButton && (
          <ContactWrapper onClick={this.closeMobileMenu}>
            <Link to={contact.url} id="test-id-contact-link">
              <FadeUp delay={1200} physics="gentle">
                <Button type="primary" large={!isMobile}>
                  Kontakta Oss
                </Button>
              </FadeUp>
            </Link>
          </ContactWrapper>
        )}
      </div>
    );
  }
}

export default Navigation;
