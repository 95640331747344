// @flow
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';

import { isTouchDevice, hex2rgba } from '../../utils/general';
import Navigation from '../Navigation';
import NoticeBanner from '../NoticeBanner';

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: Quicksand, sans-serif;
  font-weight: 400;
  margin: 0;
  letter-spacing: .5px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  margin:0;
  padding: 0;
  text-indent: 0;
  list-style: none;
}
body {
  margin: 0;
  overflow-x: hidden;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  outline: none;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px ${hex2rgba('#07206a', 0.6)} inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: #07206a !important;
}

strong {
  font-weight: 800;
}
`;

type Props = { children: React.Node, location: { pathname: string } };

type State = { isTouch: boolean };

export default class App extends React.Component<Props, State> {
  state = {
    isTouch: false,
  };

  componentDidMount() {
    this.setState({
      isTouch: isTouchDevice(),
    });
  }

  render() {
    const { isTouch } = this.state;

    return (
      <div>
        <Helmet>
          <html lang="sv" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <title>Mcon</title>
          <link
            href="https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&amp;subset=latin-ext"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Playfair+Display:700,900"
            rel="stylesheet"
          />
          <link
            rel="icon"
            type="image/png"
            href="./favicon/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="./favicon/favicon-16x16.png"
            sizes="16x16"
          />
        </Helmet>
        <GlobalStyle />
        <NoticeBanner />
        <Navigation currentPage={this.props.location.pathname} />
        {React.Children.map(this.props.children, child => {
          return React.cloneElement(child, { isTouch });
        })}
      </div>
    );
  }
}
