// @flow
import styled from 'styled-components';
import { withPrefix } from 'gatsby';
import { media } from '../../utils/style-utils';

export const Logo = styled.div`
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 999;
  background: ${({ inverted }) =>
    `url(${withPrefix(
      `./logo/mconlogotype${inverted ? '_inverted' : ''}.svg`
    )})`};

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 20px;
  cursor: pointer;

  ${media.tablet`
    left: 40px;
    top: 40px;
  `};

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 80px;
    height: 25px;
  }

  @media screen and (min-width: 1024px) {
    width: 100px;
    height: 30px;
  }
`;

export const ContactWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  -webkit-backface-visibility: hidden;

  ${media.tablet`
    right: 40px;
    bottom: 40px;
  `};
`;
