import React from 'react';
import styled, { keyframes } from 'styled-components';

import ButtonBase from '../ButtonBase';

const animation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

const Btn = styled(ButtonBase)`
  animation: 4s ${animation} ease-out infinite forwards paused;

  &:enabled {
    animation-play-state: running;
  }
`;

const Button = ({ children, onClick, ...props }) => {
  return (
    <Btn onClick={onClick} {...props}>
      {children}
    </Btn>
  );
};

Button.propTypes = {
  ...ButtonBase.propTypes,
};

Button.defaultProps = {
  ...ButtonBase.defaultProps,
};

export default Button;
