import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from '../../utils/style-utils';

const ButtonReset = css`
  display: block;
  border: none;
  margin: 0;
  text-transform: none;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  outline: 0;

  &:focus,
  &::-moz-focus-inner {
    outline: 0;
  }
`;

const boxShadow = '0px 2px 4px 0px rgba(0, 0, 0, 0.4)';
const boxShadowActive = '0px 3px 5px 0px rgba(0, 0, 0, 0.4)';
const scale = 'scale(1.02)';
const styles = {
  primary: {
    boxShadow,
    color: '#fff',
    background: 'linear-gradient(to right, #2356ac 0%, #4791eb 100%)',

    hover: {
      boxShadow: boxShadowActive,
      scale,
    },

    disabled: {
      color: 'rgba(255,255,255,0.5)',
    },
  },
  default: {
    boxShadow,
    color: '#2356ac',
    background: 'linear-gradient(to right, #fff 0%, #e1e1e1 100%)',

    hover: {
      boxShadow: boxShadowActive,
      scale,
    },

    disabled: {
      color: 'rgba(35,86,172,0.5)',
    },
  },
};

export const Button = styled.button`
  ${ButtonReset}
  background: ${({ type }) => styles[type].background};
  background-size: 200% auto;
  min-width: 120px;
  border-radius: 50px;
  font-size: ${({ large }) => (large ? '1rem' : '0.8rem')};
  font-weight: ${({ bold }) => (bold ? 800 : 400)};
  padding: ${({ large }) => (large ? '1.2rem 2.4rem' : '0.8rem 2rem')};
  color: ${({ type }) => styles[type].color};
  z-index: 99;
  box-shadow: ${({ type }) => styles[type].boxShadow};
  transition: all .3s ease-out; 
  opacity: 1;

  &:disabled {
    pointerEvents: none;
    cursor: not-allowed;
    background: ${({ type }) => styles[type].background};
    color: ${({ type }) => styles[type].disabled.color};
    box-shadow: unset;
    opacity: .6;
  }

  ${media.tablet`
    font-size: ${({ large }) => (large ? '1.2rem' : '0.8rem')}
    font-weight: ${({ bold }) => (bold ? 800 : 400)};;
    padding: ${({ large }) => (large ? '1.2rem 2.8rem' : '1rem 2.2rem')};

    &:hover:enabled {
      box-shadow: ${({ type }) => styles[type].hover.boxShadow};
      transform: ${({ type }) => styles[type].hover.scale};
      background: ${({ type }) => styles[type].hover.background};
    }
  
    &:active:enabled {
      transform: scale(0.98);
    }
  `}

`;

const ButtonBase = React.forwardRef((props, ref) => (
  <Button ref={ref} {...props}>
    {props.children}
  </Button>
));

ButtonBase.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['primary', 'default']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ButtonBase.defaultProps = {
  disabled: false,
  type: 'default',
};

export default ButtonBase;
