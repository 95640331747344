// @flow
import * as React from 'react';
import { Link } from 'gatsby';
import { TimelineLite, Power2, CSSPlugin } from 'gsap';

import MenuButton from '../MenuButton';
import CSCanvas from '../CSCanvas';
import { hex2rgba } from '../../utils/general';
import { Nav, NavList, NavItem, Fixed } from './styles';
import type { CanvasConfig, CanvasConfigParams } from './canvasConfig';
import { type MenuNavigationConfig } from '../Navigation';

const plugins = [CSSPlugin]; // Referenced to prevent the plugins being tree shaken

type Props = {
  onMenuToggle: () => mixed,
  menuConfig: MenuNavigationConfig,
  open: boolean,
};

type State = {
  isAnimating: boolean,
};

type Ref = { current: null | HTMLElement };
export default class MobileMenu extends React.Component<Props, State> {
  masterTimline = null; // main timeline
  hamburgerTimeline = null; // timeline handling animation of the hamburger in the menuButton
  nav: Ref = React.createRef(); // navigation wrapper node
  menuButton: Ref = React.createRef(); // menu button node
  mainNavItems: Array<Ref> = []; // main nav items nodes
  gsapOptions: { ease: () => mixed } = { ease: Power2.easeOut };
  canvasProps: CanvasConfig | null = null;

  state = {
    open: false,
    isAnimating: false,
  };

  componentDidMount() {
    import('./canvasConfig').then(
      ({
        canvasConfig,
      }: {
        canvasConfig: CanvasConfigParams => CanvasConfig,
      }): void => {
        this.canvasProps = canvasConfig({
          vw: window.innerWidth,
          vh: window.innerHeight,
          shapeColors: {
            from: hex2rgba('#2356ac', 0.8),
            to: hex2rgba('#4791eb', 0.8),
          },
          shapeExtensionColors: {
            from: hex2rgba('#2356ac', 0.4),
            to: hex2rgba('#4791eb', 0.4),
          },
        });
      }
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { open } = this.props;
    if (prevProps.open !== open) {
      if (open === true) {
        this.animate();
      } else {
        this.animate(true);
      }
    }
  }

  hamburgerAnimation(reverse: boolean = false): void {
    if (!this.menuButton.current) return;

    this.hamburgerTimeline = new TimelineLite({ ...this.gsapOptions });
    const hamburgers = Array.from(this.menuButton.current.children);

    this.hamburgerTimeline
      .staggerFromTo(
        hamburgers,
        0.4,
        { autoAlpha: 1, transform: 'translateX(0px)' },
        { autoAlpha: 0, transform: 'translateX(18px)' },
        0.05
      )
      .to(hamburgers, 0, { transform: 'translateX(0px)' }, 0.5)
      .to(
        hamburgers[0],
        0.4,
        {
          autoAlpha: 1,
          transform: 'translateY(4.6px) rotate(-45deg)',
        },
        0.6
      )
      .to(
        hamburgers[2],
        0.4,
        {
          autoAlpha: 1,
          transform: 'translateY(-4.6px) rotate(45deg)',
        },
        0.6
      );
    if (!reverse) {
      this.hamburgerTimeline.play();
    } else {
      this.hamburgerTimeline.reverse();
    }
    return this.hamburgerTimeline;
  }

  animate(reverse: boolean = false): void {
    if (!this.nav.current) return;

    if (this.state.isAnimating) {
      this.masterTimline.stop(0);
      this.hamburgerTimeline.stop(0);
    }

    this.masterTimline = new TimelineLite({
      onComplete: () => this.setState({ isAnimating: false }),
      onStart: () => this.setState({ isAnimating: true }),
      ...this.gsapOptions,
    });

    this.masterTimline.add(this.hamburgerAnimation(reverse));
    const canvas = this.nav.current.querySelector('canvas');
    if (!reverse) {
      this.masterTimline
        .fromTo(this.nav.current, 0.4, { autoAlpha: 0 }, { autoAlpha: 1 }, 0)
        .fromTo(
          canvas,
          0.6,
          {
            autoAlpha: 0,
            transform: 'translate3d(50%, -50%, 0)',
          },
          {
            autoAlpha: 1,
            transform: 'translate3d(0%,0%, 0)',
          },
          0.1
        )
        .staggerFromTo(
          this.mainNavItems.map(item => item.current),
          1,
          { transform: 'translateY(30px)', autoAlpha: 0 },
          { transform: 'translateY(0px)', autoAlpha: 1 },
          0.1,
          0.3
        );
    } else {
      this.masterTimline.fromTo(
        this.nav.current,
        0.6,
        { autoAlpha: 1 },
        { autoAlpha: 0 },
        0.4
      );
    }
  }

  render() {
    const { menuConfig, onMenuToggle, open } = this.props;

    return (
      <React.Fragment>
        <Fixed>
          <MenuButton
            onClick={onMenuToggle}
            active={open}
            ref={this.menuButton}
          />
        </Fixed>

        <Nav showMenu={open} ref={this.nav}>
          {this.canvasProps && <CSCanvas {...this.canvasProps} />}
          <NavList inverted={true}>
            {Object.keys(menuConfig).map(
              (navKey: string, i: number): React.Element<*> => (
                <Link
                  key={menuConfig[navKey].url}
                  to={menuConfig[navKey].url}
                  onClick={onMenuToggle}
                >
                  <NavItem
                    key={navKey}
                    ref={(this.mainNavItems[i] = React.createRef())}
                  >
                    {menuConfig[navKey].title}
                  </NavItem>
                </Link>
              )
            )}
          </NavList>
        </Nav>
      </React.Fragment>
    );
  }
}
