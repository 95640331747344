// @flow
import styled from 'styled-components';
import { media } from '../../utils/style-utils';

export const Nav = styled.nav`
  visibility: ${({ showMenu }) => (showMenu ? 'visible' : 'hidden')};
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 998;

  ${media.tablet`
    position: absolute;
    background: transparent;
    flex-direction: row;
    top: 40px;
    right: 40px;
    left: auto;
    bottom: auto;
  `};
`;

export const NavList = styled.ul`
  position: absolute;
  right: 64px;
  top: 15%;
  color: ${({ inverted }) => (!inverted ? '#000' : '#fff')};
  text-align: right;
  z-index: 999;

  ${media.tablet`
    display: flex;
    right: 0px;
    top: 0px;
  `};
`;

export const NavItem = styled.li`
  display: block;
  font-size: 18px;
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 22px;
  opacity: 0;
  mix-blend-mode: soft-light;
  font-family: 'Quicksand', sans-serif;

  ${media.tablet`
    user-select: none;
    font-size: 18px;
    padding: 0.4rem 1.2rem;
    border-radius: 1.2rem;
    background: ${({ selected }) =>
      `rgba(0, 0, 0, ${selected ? '0.07' : '0'})`};
    opacity: 1;
    transition: background 0.3s ease-out;
    white-space: nowrap;
    cursor: ${({ selected }) => (selected ? 'default' : 'pointer')}

    &:hover {
      background: rgba(0, 0, 0, 0.07);
    }
  `};
`;

export const Fixed = styled.div`
  position: fixed;
  right: 20px;
  top: 12px;
  z-index: 999;
`;

export const PageSections = styled.div`
  padding-bottom: 28px;
`;

export const SubNavItem = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 300;
  margin: 16px 0px;
  cursor: pointer;
`;

// export const MenuFARLogo = styled.div`
//   background-image: url(static/logo/far.svg);
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
//   width: 50px;
//   height: 38px;
//   position: absolute;
//   right: 64px;
//   top: calc(15% + 150px);
//   z-index: 20;

//   ${media.phone`
//     top: calc(15% + 200px);
//   `};
// `;
