// @flow
import * as React from 'react';
import { Link } from 'gatsby';

import { Nav, NavList, NavItem } from './styles';
import { type MenuNavigationConfig } from '../Navigation';

type Props = {
  inverted: boolean,
  menuConfig: MenuNavigationConfig,
  currentPage: string,
};

const DesktopMenu = ({ inverted, menuConfig, currentPage }: Props) => (
  <Nav showMenu={true}>
    <NavList inverted={inverted}>
      {Object.keys(menuConfig).map(
        (navKey: string, i: number): React.Element<*> => {
          const { title, url } = menuConfig[navKey];
          if (url === currentPage) {
            return (
              <NavItem key={navKey} selected>
                {title}
              </NavItem>
            );
          }
          return (
            <Link to={url} key={url} id={`test-id-${navKey}-link`}>
              <NavItem>{title}</NavItem>
            </Link>
          );
        }
      )}
    </NavList>
  </Nav>
);

export default DesktopMenu;
