import styled from 'styled-components';

import { media } from '../../utils/style-utils';
import ButtonBase from '../ButtonBase';

export const Banner = styled.div`
  position: fixed;
  top: 0;
  left: 90px;
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  border-radius: 0 0 12px 12px;
  z-index: 500;
  width: 100%;

  ${media.tablet`
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px 12px 0 0;
    flex-wrap: nowrap;
    width: auto;
    max-width: 680px;
  `}
`;

export const NoticeText = styled.p`
  font-size: 10px;
  margin: 0 130px 8px 0;
  color: #ddd;

  ${media.tablet`
    font-size: 11px;
    margin: 0 16px 0 0;
  `}
`;

export const BannerButton = styled(ButtonBase)`
  min-width: 50px;
  padding: 0.2rem 0.8rem;
  box-shadow: none;
  background: transparent;
  font-size: 11px;
  white-space: nowrap;

  &:hover,
  &:hover:enabled {
    box-shadow: none;
  }

  &:first-of-type {
    padding: 0.2rem 0.8rem 0.2rem 0;
  }
`;
