import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonBase from '../ButtonBase';

const Btn = styled(ButtonBase)`
  &#mobileMenu {
    border-radius: 88px;
    height: 44px;
    width: 44px;
    min-width: 44px;
    max-width: 44px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
  }
`;

const Hamburger = styled.div`
  width: 18px;
  margin: 1.5px 0px;
  height: 2px;
  opacity: 1;
  visibility: visible;
  backface-visibility: visible;
  background: linear-gradient(to right, #2356ac 0%, #4791eb 100%);
`;

const MenuButton = React.forwardRef(({ onClick, active, ...props }, ref) => (
  <Btn id="mobileMenu" ref={ref} onClick={onClick} active={active} {...props}>
    <Hamburger />
    <Hamburger />
    <Hamburger />
  </Btn>
));

MenuButton.propTypes = {
  ...ButtonBase.propTypes,
  active: PropTypes.bool,
};

MenuButton.defaultProps = {
  ...ButtonBase.defaultProps,
  active: false,
};

export default MenuButton;
